<template>
  <div class="row">
    <div class="col-6">
      <label class="mt-2"
        >Halaman {{ pagination.curent_page }} dari
        {{ pagination.total_data }} data</label
      >
    </div>
    <div class="col-6">
      <div class="float-end">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li
              class="page-item"
              :class="{ disabled: pagination.curent_page == 1 }"
              :disabled="pagination.curent_page == 1"
              @click.prevent="changePage(pagination.curent_page - 1)"
            >
              <a class="page-link" href="#" aria-label="Sebelumnya">
                <span aria-hidden="true">Prev</span>
              </a>
            </li>
            <li
              class="page-item"
              v-for="page in pageNumbers"
              :key="page"
              :class="{ active: pagination.curent_page == page }"
              @click.prevent="changePage(page)"
            >
              <a class="page-link" href="#">{{ page }}</a>
            </li>

            <li
              class="page-item"
              :class="{
                disabled: pagination.curent_page == pagination.total_pages,
              }"
              :disabled="pagination.curent_page == pagination.total_pages"
              @click.prevent="changePage(pagination.curent_page + 1)"
            >
              <a class="page-link" href="#" aria-label="Selnjutnya">
                <span aria-hidden="true">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Paginator",
  props: {
    pagination: { type: Object, required: true },
  },
  computed: {
    pageNumbers() {
      let pages = [];
      const { curent_page, total_pages } = this.pagination;
      if (total_pages == 1) return [];

      pages.push(1);
      if (curent_page > 2) {
        pages.push(curent_page - 1);
      }
      if (curent_page != 1 && curent_page != total_pages) {
        pages.push(curent_page);
      }
      if (curent_page + 1 < total_pages) {
        pages.push(curent_page + 1);
      }
      pages.push(total_pages);

      return pages;
    },
  },
  methods: {
    changePage(page) {
      if (page < 1 || page > this.pagination.total_pages) {
        return;
      }
      this.$emit("pageChanged", page);
    },
  },
};
</script>