<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
            <h4 class="mb-sm-0">Investor</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Daftar Investor</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Daftar Investor</h4>
              <router-link
                :to="{ name: 'investor.form' }"
                type="button"
                class="btn btn-primary waves-effect waves-light ms-2"
                v-if="permission.can('investor create')"
              >
                <i class="bx bx-plus font-size-16 align-middle me-2"></i>
                Investor
              </router-link>
            </div>

            <div class="card-body">
              <DataTable :url="dataTableUrl" :columns="columnHeaders" :actions="actions" :reload="reloadDataTable" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/datatable/DataTable.vue";
import { endpoint } from "../../host";
import globalService from "../../services/global-service";
import permissonService from "../../services/permisson-service";

export default {
  components: {
    DataTable,
  },

  created() { },

  data() {
    return {
      permission: permissonService,
      list: {},
      filter: {
        is_approved_hrd: "",
      },
      reloadDataTable: false,
      dataTableUrl: endpoint.investor,
      actions: [
        {
          icon: "bx bx-show",
          color: "btn-info",
          tooltip: "detail",
          onclick: (data) => {
            this.$router.push({
              name: "investor.detail",
              params: { id: data.id },
            });
          },
        },
        {
          icon: "bx bx-edit",
          color: "btn-warning",
          tooltip: "ganti email",
          onclick: (data) => {
            this.$router.push({
              name: "investor.change-email",
              params: { id: data.user.id },
            });
          },
        },
      ],
      columnHeaders: [
        { name: "nik", text: "NIK" },
        { name: "user.name", text: "Name" },
        { name: "user.email", text: "Email" },
        { name: "slot", text: "Slot" },
        {
          text: "Saldo",
          render: (data) => {
            return globalService.toIdr(data.user.wallet.saldo);
          },
        },
      ],
    };
  },
};
</script>

<style>
</style>