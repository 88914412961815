<template>
  <div class="row mb-3">
    <div class="col-md-3">
      <div class="input-group mb-3">
        <label for="" class="mt-2 me-2">Tampilkan</label>
        <select class="form-select" v-model="perPage" @change="handlePerPage">
          <option v-for="page in pageOptions" :key="page" :value="page">
            {{ page }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-5"></div>
    <div class="col-md-4">
      <div class="input-group mb-3">
        <label for="" class="mt-2 me-2">Cari</label>
        <input
          type="text"
          class="form-control"
          placeholder="Masukkan kata kunci..."
          v-model="search"
          v-on:keyup.enter="handleSearch"
          id="search"
        />
        <button class="btn btn-primary" type="button">
          <i class="bx bx-search" @click.prevent="handleSearch"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th
            class="hash-filter"
            v-for="column in columns"
            :key="column"
            @click="updateSortColumn(column['name'])"
          >
            {{ column["text"] }}
            <span v-if="column['name'] === sortField">
              <i v-if="sortOrder === 'asc'" class="fas fa-arrow-up"></i>
              <i v-else class="fas fa-arrow-down"></i>
            </span>
          </th>
          <th v-if="actions.length > 0">Aksi</th>
        </tr>
      </thead>
      <tbody v-if="load">
        <tr v-for="i in perPage" :key="i">
          <td v-for="column in columns" :key="column">
            <FreeStyleShimmer :is-loading="true" height="15px"  :width="10"/>
          </td>
          <th v-if="actions.length > 0">
            <FreeStyleShimmer :is-loading="true" height="15px" :width="10" />
          </th>
        </tr>
      </tbody>
      <tbody v-if="!load">
        <tr v-if="tableData.length == 0 && !load">
          <td :colspan="columns.length + 1" class="text-center">
            <p class="text-center mt-2">Tidak ada data ditemukan</p>
          </td>
        </tr>

        <tr v-for="data in tableData" :key="data">
          <td v-for="(column, index) in columns" :key="column">
            <span v-html="columnDataItem(data, column['name'], index)"></span>
          </td>
          <td v-if="actions.length > 0">
            <div class="btn-group">
              <button
                class="btn btn-sm"
                :class="action['color']"
                v-for="action in actions"
                :key="action"
                @click="action.onclick(data)"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="action['tooltip']"
              >
                <i class="fa fa-sm" :class="action['icon']"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Paginator
    v-if="tableData.length > 0"
    :pagination="pagination"
    @pageChanged="handlePageChange"
  />
</template>
<style scoped>
.hash-filter {
  cursor: pointer;
}
</style>
<script>
import http from "../../services/http-service";
import Paginator from "./Paginator.vue";
import { FreeStyleShimmer } from "vue3-shimmer";
import errorService from "../../services/error-service";
import httpService from "../../services/http-service";
export default {
  name: "DataTable",
  components: {
    Paginator,
    FreeStyleShimmer,
  },
  props: {
    url: { type: String, required: true },
    columns: { type: Array, required: true },
    actions: { type: Array, required: true },
    reload: { type: Boolean, required: false },
  },
  data() {
    return {
      load: false,
      tableData: [],
      sortField: this.columns[0]["name"],
      sortOrder: "desc",
      search: null,
      pageOptions: [5, 10, 25, 50, 100],
      perPage: 10,
      page: 1,
      pagination: {
        total: 1,
        count: 1,
        per_page: 1,
        current_page: null,
        total_pages: null,
      },
    };
  },
  created() {
    this.fetchData();
    setTimeout(() => {
      document.getElementById("search").focus();
    }, 50);
  },
  watch: {
    reload: function () {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      try {
        this.load = true;
        const params = {
          sort_field: this.sortField,
          sort_order: this.sortOrder,
          search: this.search,
          limit: this.perPage,
          page: this.page,
        };
        const r = await http.get(this.url, {
          params: params,
          headers: httpService.authHeader(),
        });
        this.tableData = r.data.data;
        this.pagination = r.data.pagination;
        this.load = false;
      } catch (error) {
        this.load = false;
        errorService.displayError(error);
      }
    },

    updateSortColumn(column) {
      if (column == this.sortField) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      } else {
        this.sortField = column;
      }
      this.fetchData();
    },

    handleSearch() {
      this.page = 1;
      this.fetchData();
    },

    handlePerPage() {
      this.page = 1;
      this.fetchData();
    },

    handlePageChange(page) {
      this.page = page;
      this.fetchData();
    },

    columnDataItem(data, item, index) {
      if (this.columns[index].render) {
        return this.columns[index].render(data);
      } else {
        let items = item.split(".");
        if (items.length > 1) {
          let a = data;
          items.forEach((val) => {
            a = a[val];
          });
          return a;
        }
        return data[item];
      }

      // return '<span class="badge badge-pill badge-soft-success font-size-11">Paid</span>';
    },
  },
  computed: {},
};
</script>